import { CommonModule, UpperCasePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@services";
import type { Language } from "src/app/transloco.config";

@Component({
  selector: "app-language-dropdown",
  standalone: true,
  imports: [MatMenuModule, UpperCasePipe, CommonModule],
  templateUrl: "./language-dropdown.component.html",
  styleUrls: ["./language-dropdown.component.scss"],
})
export class LanguageDropdownComponent implements OnInit {
  selectedLanguage: Language = "en";
  isMenuOpen = false;
  @Input() isSignInPage: boolean = false;

  availableLanguages: { code: Language }[] = [
    { code: "en" },
    //TODO: disabled TR language, in case it needs enabling again
    //  { code: "tr" },
    { code: "es" },
  ];

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.getSelectedLanguage();
  }

  get dropdownClass() {
    return this.isSignInPage ? "sign-in-page" : "not-sign-in";
  }

  handleMenuOpen(): void {
    this.isMenuOpen = true;
  }

  handleMenuClose(): void {
    this.isMenuOpen = false;
  }

  changeLanguage(language: Language): void {
    this.translateService.setLanguage(language);
    this.selectedLanguage = language;

    let currentPath = this.router.url;

    currentPath = currentPath.replace(/^\/(en|es)/, "");

    this.router.navigate([`/${language}${currentPath}`]).then(() => {
      window.location.reload();
    });
  }
}
