import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, RouterLinkActive, RouterLink, Params } from "@angular/router";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";
import { NgScrollbar, NgScrollbarModule } from "ngx-scrollbar";
import { Store, clearCurrentUser } from "@store";
import { socialLink } from "@constants";
import { CommonService } from "../common.service";
import { LanguageDropdownComponent } from "src/app/shared/components/language-dropdown/language-dropdown.component";
import { SideNavItemComponent } from "./side-nav-item/side-nav-item.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    NgScrollbarModule,
    TranslocoModule,
    RouterLinkActive,
    RouterLink,
    LanguageDropdownComponent,
    SideNavItemComponent,
    NgOptimizedImage,
    MatProgressSpinnerModule,
  ],
})
export class SideNavComponent implements OnInit {
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef!: NgScrollbar;

  social = socialLink;
  isCollapsed = false;
  expanded: boolean[] = [false, false, false];
  queryParams: Params;
  navigationLoader: boolean = false;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private store: Store,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngOnInit(): void {
    this.commonService.sidebar.subscribe(() => {
      this.isCollapsed = !this.isCollapsed;
    });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
    this.store.dispatch(clearCurrentUser());
  }

  expandMenu(index: number) {
    this.expanded[index] = !this.expanded[index];
  }

  navigateToUrl(url: string) {
    this.navigationLoader = true;
    const navigationExtras = this.queryParams?.affiliateId ? { queryParams: this.queryParams } : {};

    this.router.navigate([url], navigationExtras).finally(() => {
      this.navigationLoader = false;
    });
  }
}
