import { Component, Input } from "@angular/core";
import { ActivatedRoute, Params, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { CommonService } from "../../common.service";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-side-nav-item",
  templateUrl: "./side-nav-item.component.html",
  styleUrls: ["./side-nav-item.component.scss"],
  standalone: true,
  imports: [NgOptimizedImage, CommonModule, RouterLinkActive, RouterLink, MatProgressSpinnerModule],
})
export class SideNavItemComponent {
  @Input({ required: true }) label = "";
  @Input({ required: true }) navigateTo = "";
  @Input({ required: false }) image = "";

  navigationLoader: boolean = false;

  queryParams: Params;

  constructor(
    private router: Router,
    public commonService: CommonService,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngOnInit(): void {
    if (this.image === "") {
      this.image = this.navigateTo;
    }
  }

  navigateToUrl(url: string) {
    this.navigationLoader = true;
    const navigationExtras = this.queryParams?.affiliateId ? { queryParams: this.queryParams } : {};

    this.router.navigate([url], navigationExtras).finally(() => {
      this.navigationLoader = false;
    });
  }
}
