<li class="menu-item" aria-haspopup="true">
  <a
    href="javascript:;"
    [routerLink]="['/' + navigateTo]"
    routerLinkActive="active"
    class="menu-link"
    (click)="commonService.showSidenav(); navigateToUrl(navigateTo)"
    #routerLinkActive="routerLinkActive"
  >
    <span class="menu-text">
      <span class="icon-container" [ngClass]="{ loading: navigationLoader }">
        <mat-progress-spinner
          *ngIf="navigationLoader"
          mode="indeterminate"
          diameter="18"
          strokeWidth="3"
          color="primary"
        ></mat-progress-spinner>

        <img
          class="icon-active"
          src="assets/svg/side-nav-items/{{ image }}-active.svg"
          alt=""
          height="18"
          width="18"
          preload
        />
        <img
          class="icon-inactive"
          src="assets/svg/side-nav-items/{{ image }}-not-active.svg"
          alt=""
          height="18"
          width="18"
        />
      </span>
      {{ label }}
    </span>
  </a>
</li>
