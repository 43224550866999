import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { TranslateService } from "../service/translate.service";

@Injectable({
  providedIn: "root",
})
export class LanguageResolver implements Resolve<void> {
  private readonly supportedLanguages: string[] = ["en", "es"];

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    let lang = route.paramMap.get("lang") || "en";

    if (!this.supportedLanguages.includes(lang)) {
      lang = "en";
    }

    if (lang !== this.translateService.getSelectedLanguage()) {
      this.translateService.setLanguage(lang as "en" | "es");

      this.router.navigate([`/${lang}`]).then(() => {
        window.location.reload();
      });
    }
  }
}
