<div class="container pb-1">
  <p class="mt-4">
    All information provided on this site is intended solely for the study purposes related to
    trading on financial markets and does not serve in any way as a specific investment
    recommendation, business recommendation, investment oppurtunity analysis or similar general
    recommendation regarding the trading of investment instruments. Trading in financial markets is
    a high-risk activity and it is advised not to risk more than one can afford to lose! Bright
    Funded Evaluation Global s.r.o./Bright Funded Evaluation US s.r.o., legal address: Purkynova
    2121/3, Prague, 11000, Czech Republic, does not provide any of the investment services listed in
    the Capital Market Undertakings Act No. 256/2004 Coll. The Information on this is not directed
    at residents in any country or jurisdiction where such distribution or use would be contrary to
    local laws or regualtions. Bright Fundeds Evaluation Global s.r.o./Bright Fundeds Evaluation US
    s.r.o. and Bright Funded s.r.o., legal address: Purkynova 3, Prague, 11000, Crech Replublic are
    not a broker and do not accept deposits. The offered technical solution for the Bright Funded
    platforms and data feed is powered by the institutional liquidity providers.
  </p>
  <p>2025 &copy; Copyright - Bright Funded.com Made with ❤️ for trading.</p>
</div>
