<div
  class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
  id="kt_aside"
  [ngClass]="{ navVisible: isCollapsed }"
  *transloco="let t; read: 'app.sideNav'"
>
  <a
    href="javascript:;"
    (click)="navigateToUrl('dashboard')"
    class="menu-link menu-toggle header-logo"
  >
    <img src="assets/images/header-logo.svg" class="max-h-70px" alt="" />
  </a>
  <div class="aside-menu-wrapper flex-column-fluid flex-fill" id="kt_aside_menu_wrapper">
    <div id="kt_aside_menu" class="aside-menu mt-4">
      <ng-scrollbar
        class="scroll-event"
        thumbClass="white-scrollbars"
        visibility="hover"
        appearance="standard"
      >
        <ul class="menu-nav">
          <ng-container>
            <li class="menu-section">
              <h4 class="menu-text">{{ t("heading") }}</h4>
            </li>

            <app-side-nav-item [label]="t('item.1')" navigateTo="dashboard" />
            <app-side-nav-item [label]="t('item.2')" navigateTo="profile" image="profile" />
            <app-side-nav-item [label]="t('item.3')" navigateTo="earn" />
            <app-side-nav-item [label]="t('item.4')" navigateTo="accounts" />
            <app-side-nav-item [label]="t('item.6')" navigateTo="payouts" />
            <app-side-nav-item [label]="t('item.12')" navigateTo="contracts" />
            <app-side-nav-item
              [label]="t('item.7')"
              navigateTo="affiliate/dashboard"
              image="affiliate"
            />
            <app-side-nav-item [label]="t('item.8')" navigateTo="trading-academy" image="academy" />
            <app-side-nav-item [label]="t('item.9')" navigateTo="billing" />
            <app-side-nav-item [label]="t('item.10')" navigateTo="download" />
            <app-side-nav-item [label]="t('item.11')" navigateTo="contact-us" image="contact" />
          </ng-container>
          <li class="menu-item menu-item--secondary d-block d-lg-none">
            <app-language-dropdown />
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>
  <div class="sidenav_footer">
    <a
      class="HashLinkCustom_hashLinkCustom HashLinkCustom_hashLinkCustom_secondary"
      href="{{ social.discord }}"
      target="_blank"
    >
      <p>{{ t("buttonLabel") }}</p>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4511 4.00095C13.4536 3.53595 12.3736 3.19845 11.2486 3.00345C11.2388 3.00314 11.2289 3.00499 11.2199 3.00888C11.2108 3.01276 11.2027 3.01859 11.1961 3.02595C11.0611 3.27345 10.9036 3.59595 10.7986 3.84345C9.60537 3.66345 8.39187 3.66345 7.19862 3.84345C7.09362 3.58845 6.93612 3.27345 6.79362 3.02595C6.78612 3.01095 6.76362 3.00345 6.74112 3.00345C5.61612 3.19845 4.54362 3.53595 3.53862 4.00095C3.53112 4.00095 3.52362 4.00845 3.51612 4.01595C1.47612 7.06845 0.913623 10.0385 1.19112 12.9785C1.19112 12.9935 1.19862 13.0085 1.21362 13.016C2.56362 14.006 3.86112 14.606 5.14362 15.0035C5.16612 15.011 5.18862 15.0035 5.19612 14.9885C5.49612 14.576 5.76612 14.141 5.99862 13.6835C6.01362 13.6535 5.99862 13.6235 5.96862 13.616C5.54112 13.451 5.13612 13.256 4.73862 13.031C4.70862 13.016 4.70862 12.971 4.73112 12.9485C4.81362 12.8885 4.89612 12.821 4.97862 12.761C4.99362 12.746 5.01612 12.746 5.03112 12.7535C7.61112 13.931 10.3936 13.931 12.9436 12.7535C12.9586 12.746 12.9811 12.746 12.9961 12.761C13.0786 12.8285 13.1611 12.8885 13.2436 12.956C13.2736 12.9785 13.2736 13.0235 13.2361 13.0385C12.8461 13.271 12.4336 13.4585 12.0061 13.6235C11.9761 13.631 11.9686 13.6685 11.9761 13.691C12.2161 14.1485 12.4861 14.5835 12.7786 14.996C12.8011 15.0035 12.8236 15.011 12.8461 15.0035C14.1361 14.606 15.4336 14.006 16.7836 13.016C16.7986 13.0085 16.8061 12.9935 16.8061 12.9785C17.1361 9.58095 16.2586 6.63345 14.4811 4.01595C14.4736 4.00845 14.4661 4.00095 14.4511 4.00095ZM6.38862 11.186C5.61612 11.186 4.97112 10.4735 4.97112 9.59595C4.97112 8.71845 5.60112 8.00595 6.38862 8.00595C7.18362 8.00595 7.81362 8.72595 7.80612 9.59595C7.80612 10.4735 7.17612 11.186 6.38862 11.186ZM11.6161 11.186C10.8436 11.186 10.1986 10.4735 10.1986 9.59595C10.1986 8.71845 10.8286 8.00595 11.6161 8.00595C12.4111 8.00595 13.0411 8.72595 13.0336 9.59595C13.0336 10.4735 12.4111 11.186 11.6161 11.186Z"
          fill="white"
        ></path>
      </svg>
    </a>
  </div>
</div>

@if (isCollapsed) {
  <div
    class="overlay"
    [ngClass]="{ navVisible: isCollapsed }"
    (click)="commonService.showSidenav()"
  ></div>
}
