<div class="navigation-tabs">
  @for (tab of tabs; track tab) {
    <ul class="navigation-tabs__list">
      <li class="navigation-tabs__list-item">
        <a
          class="navigation-tabs__list-link"
          [routerLinkActive]="['navigation-tabs__list-link--active']"
          [routerLink]="tab.link"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ tab.label }}
        </a>
      </li>
    </ul>
  }
</div>
